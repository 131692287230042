if (!Array.prototype.some) {
  Array.prototype.some = function (fn, ctx) {
    var context, i;

    if (this == null) {
      throw new TypeError("this is null or not defined");
    }

    var source = Object(this);
    var len = source.length >>> 0;

    if (typeof fn !== "function") {
      throw new TypeError(fn + " is not a function");
    }

    if (arguments.length > 1) {
      context = ctx;
    }

    i = 0;

    while (i < len) {
      if (i in source) {
        var test = fn.call(context, source[i], i, source);

        if (test) {
          return true;
        }
      }

      i++;
    }

    return false;
  };
}

export default {};