import _index from "./index";
import _input from "./input";
import _inline from "./inline";
import _isInput from "./isInput";
var exports = {};
var index = _index;
var input = _input;
var inline = _inline;
var isInput = _isInput;

function core(elem, options) {
  var cal;
  var existing = index.find(elem);

  if (existing) {
    return existing;
  }

  if (isInput(elem)) {
    cal = input(elem, options);
  } else {
    cal = inline(elem, options);
  }

  index.assign(elem, cal);
  return cal;
}

exports = core;
export default exports;