import _function from "./polyfills/function.bind";
import _array from "./polyfills/array.foreach";
import _array2 from "./polyfills/array.map";
import _array3 from "./polyfills/array.filter";
import _array4 from "./polyfills/array.isarray";
import _array5 from "./polyfills/array.indexof";
import _array6 from "./polyfills/array.some";
import _string from "./polyfills/string.trim";
import _object from "./polyfills/object.keys";
import _core from "./core";
import _index from "./index";
import _use from "./use";
import _validators from "./validators";
var exports = {};
// these are only required for IE < 9
// maybe move to IE-specific distro?
_function;
_array;
_array2;
_array3;
_array4;
_array5;
_array6;
_string;
_object;
var core = _core;
var index = _index;
var use = _use;
core.use = use.bind(core);
core.find = index.find;
core.val = _validators;
exports = core;
export default exports;