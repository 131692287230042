import _parse from "./parse";
import _isInput from "./isInput";
import _momentum from "./momentum";
var exports = {};
var parse = _parse;
var isInput = _isInput;
var momentum = _momentum;

function defaults(options, cal) {
  var temp;
  var no;
  var o = options || {};

  if (o.autoHideOnClick === no) {
    o.autoHideOnClick = true;
  }

  if (o.autoHideOnBlur === no) {
    o.autoHideOnBlur = true;
  }

  if (o.autoClose === no) {
    o.autoClose = true;
  }

  if (o.appendTo === no) {
    o.appendTo = document.body;
  }

  if (o.appendTo === "parent") {
    if (isInput(cal.associated)) {
      o.appendTo = cal.associated.parentNode;
    } else {
      throw new Error("Inline calendars must be appended to a parent node explicitly.");
    }
  }

  if (o.invalidate === no) {
    o.invalidate = true;
  }

  if (o.required === no) {
    o.required = false;
  }

  if (o.date === no) {
    o.date = true;
  }

  if (o.time === no) {
    o.time = true;
  }

  if (o.date === false && o.time === false) {
    throw new Error("At least one of `date` or `time` must be `true`.");
  }

  if (o.inputFormat === no) {
    if (o.date && o.time) {
      o.inputFormat = "YYYY-MM-DD HH:mm";
    } else if (o.date) {
      o.inputFormat = "YYYY-MM-DD";
    } else {
      o.inputFormat = "HH:mm";
    }
  }

  if (o.initialValue === no) {
    o.initialValue = null;
  } else {
    o.initialValue = parse(o.initialValue, o.inputFormat);
  }

  if (o.min === no) {
    o.min = null;
  } else {
    o.min = parse(o.min, o.inputFormat);
  }

  if (o.max === no) {
    o.max = null;
  } else {
    o.max = parse(o.max, o.inputFormat);
  }

  if (o.timeInterval === no) {
    o.timeInterval = 60 * 30;
  } // 30 minutes by default


  if (o.min && o.max) {
    if (o.max.isBefore(o.min)) {
      temp = o.max;
      o.max = o.min;
      o.min = temp;
    }

    if (o.date === true) {
      if (o.max.clone().subtract(1, "days").isBefore(o.min)) {
        throw new Error("`max` must be at least one day after `min`");
      }
    } else if (o.timeInterval * 1000 - o.min % (o.timeInterval * 1000) > o.max - o.min) {
      throw new Error("`min` to `max` range must allow for at least one time option that matches `timeInterval`");
    }
  }

  if (o.dateValidator === no) {
    o.dateValidator = Function.prototype;
  }

  if (o.timeValidator === no) {
    o.timeValidator = Function.prototype;
  }

  if (o.timeFormat === no) {
    o.timeFormat = "HH:mm";
  }

  if (o.weekStart === no) {
    o.weekStart = momentum.moment().weekday(0).day();
  }

  if (o.weekdayFormat === no) {
    o.weekdayFormat = "min";
  }

  if (o.weekdayFormat === "long") {
    o.weekdayFormat = momentum.moment.weekdays();
  } else if (o.weekdayFormat === "short") {
    o.weekdayFormat = momentum.moment.weekdaysShort();
  } else if (o.weekdayFormat === "min") {
    o.weekdayFormat = momentum.moment.weekdaysMin();
  } else if (!Array.isArray(o.weekdayFormat) || o.weekdayFormat.length < 7) {
    throw new Error("`weekdays` must be `min`, `short`, or `long`");
  }

  if (o.monthsInCalendar === no) {
    o.monthsInCalendar = 1;
  }

  if (o.monthFormat === no) {
    o.monthFormat = "MMMM YYYY";
  }

  if (o.dayFormat === no) {
    o.dayFormat = "DD";
  }

  if (o.styles === no) {
    o.styles = {};
  }

  o.styles._isStylesConfiguration = true;
  var styl = o.styles;

  if (styl.back === no) {
    styl.back = "rd-back";
  }

  if (styl.container === no) {
    styl.container = "rd-container";
  }

  if (styl.positioned === no) {
    styl.positioned = "rd-container-attachment";
  }

  if (styl.date === no) {
    styl.date = "rd-date";
  }

  if (styl.dayBody === no) {
    styl.dayBody = "rd-days-body";
  }

  if (styl.dayBodyElem === no) {
    styl.dayBodyElem = "rd-day-body";
  }

  if (styl.dayPrevMonth === no) {
    styl.dayPrevMonth = "rd-day-prev-month";
  }

  if (styl.dayNextMonth === no) {
    styl.dayNextMonth = "rd-day-next-month";
  }

  if (styl.dayDisabled === no) {
    styl.dayDisabled = "rd-day-disabled";
  }

  if (styl.dayConcealed === no) {
    styl.dayConcealed = "rd-day-concealed";
  }

  if (styl.dayHead === no) {
    styl.dayHead = "rd-days-head";
  }

  if (styl.dayHeadElem === no) {
    styl.dayHeadElem = "rd-day-head";
  }

  if (styl.dayRow === no) {
    styl.dayRow = "rd-days-row";
  }

  if (styl.dayTable === no) {
    styl.dayTable = "rd-days";
  }

  if (styl.month === no) {
    styl.month = "rd-month";
  }

  if (styl.monthLabel === no) {
    styl.monthLabel = "rd-month-label";
  }

  if (styl.next === no) {
    styl.next = "rd-next";
  }

  if (styl.selectedDay === no) {
    styl.selectedDay = "rd-day-selected";
  }

  if (styl.selectedTime === no) {
    styl.selectedTime = "rd-time-selected";
  }

  if (styl.time === no) {
    styl.time = "rd-time";
  }

  if (styl.timeList === no) {
    styl.timeList = "rd-time-list";
  }

  if (styl.timeOption === no) {
    styl.timeOption = "rd-time-option";
  }

  return o;
}

exports = defaults;
export default exports;