import _momentum from "./momentum";
var exports = {};
var momentum = _momentum;

function raw(date, format) {
  if (typeof date === "string") {
    return momentum.moment(date, format);
  }

  if (Object.prototype.toString.call(date) === "[object Date]") {
    return momentum.moment(date);
  }

  if (momentum.isMoment(date)) {
    return date.clone();
  }
}

function parse(date, format) {
  var m = raw(date, typeof format === "string" ? format : null);
  return m && m.isValid() ? m : null;
}

exports = parse;
export default exports;