if (!Function.prototype.bind) {
  Function.prototype.bind = function (context) {
    if (typeof this !== "function") {
      throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
    }

    var curried = Array.prototype.slice.call(arguments, 1);
    var original = this;

    var NoOp = function () {};

    var bound = function () {
      var ctx = this instanceof NoOp && context ? this : context;
      var args = curried.concat(Array.prototype.slice.call(arguments));
      return original.apply(ctx, args);
    };

    NoOp.prototype = this.prototype;
    bound.prototype = new NoOp();
    return bound;
  };
}

export default {};