import _momentum from "./momentum";
var exports = {};
var momentum = _momentum; // naïve implementation, specifically meant to clone `options` objects

function clone(thing) {
  var copy = {};
  var value;

  for (var key in thing) {
    value = thing[key];

    if (!value) {
      copy[key] = value;
    } else if (momentum.isMoment(value)) {
      copy[key] = value.clone();
    } else if (value._isStylesConfiguration) {
      copy[key] = clone(value);
    } else {
      copy[key] = value;
    }
  }

  return copy;
}

exports = clone;
export default exports;