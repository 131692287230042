if (!Array.prototype.map) {
  Array.prototype.map = function (fn, ctx) {
    var context, result, i;

    if (this == null) {
      throw new TypeError("this is null or not defined");
    }

    var source = Object(this);
    var len = source.length >>> 0;

    if (typeof fn !== "function") {
      throw new TypeError(fn + " is not a function");
    }

    if (arguments.length > 1) {
      context = ctx;
    }

    result = new Array(len);
    i = 0;

    while (i < len) {
      if (i in source) {
        result[i] = fn.call(context, source[i], i, source);
      }

      i++;
    }

    return result;
  };
}

export default {};