var exports = {};

function dom(options) {
  var o = options || {};

  if (!o.type) {
    o.type = "div";
  }

  var elem = document.createElement(o.type);

  if (o.className) {
    elem.className = o.className;
  }

  if (o.text) {
    elem.innerText = elem.textContent = o.text;
  }

  if (o.attributes) {
    Object.keys(o.attributes).forEach(function (key) {
      elem.setAttribute(key, o.attributes[key]);
    });
  }

  if (o.parent) {
    o.parent.appendChild(elem);
  }

  return elem;
}

exports = dom;
export default exports;