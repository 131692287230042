import _isInput from "./isInput";
var exports = {};
var isInput = _isInput;
var bindings = {};

function has(source, target) {
  var binding = bindings[source.id];
  return binding && binding[target.id];
}

function insert(source, target) {
  var binding = bindings[source.id];

  if (!binding) {
    binding = bindings[source.id] = {};
  }

  var invalidate = invalidator(target);
  binding[target.id] = invalidate;
  source.on("data", invalidate);
  source.on("destroyed", remove.bind(null, source, target));
}

function remove(source, target) {
  var binding = bindings[source.id];

  if (!binding) {
    return;
  }

  var invalidate = binding[target.id];
  source.off("data", invalidate);
  delete binding[target.id];
}

function invalidator(target) {
  return function invalidate() {
    target.refresh();
  };
}

function add(source, target) {
  if (isInput(target.associated) || has(source, target)) {
    return;
  }

  insert(source, target);
}

exports = {
  add: add,
  remove: remove
};
export default exports;