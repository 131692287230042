var exports = {};

function isMoment(value) {
  return value && Object.prototype.hasOwnProperty.call(value, "_isAMomentObject");
}

var api = {
  moment: null,
  isMoment: isMoment
};
exports = api;
export default exports;