var exports = {};
var trim = /^\s+|\s+$/g;
var whitespace = /\s+/;

function classes(node) {
  return node.className.replace(trim, "").split(whitespace);
}

function set(node, value) {
  node.className = value.join(" ");
}

function add(node, value) {
  var values = remove(node, value);
  values.push(value);
  set(node, values);
}

function remove(node, value) {
  var values = classes(node);
  var i = values.indexOf(value);

  if (i !== -1) {
    values.splice(i, 1);
    set(node, values);
  }

  return values;
}

function contains(node, value) {
  return classes(node).indexOf(value) !== -1;
}

exports = {
  add: add,
  remove: remove,
  contains: contains
};
export default exports;