import _calendar from "./calendar";
var exports = {};
var calendar = _calendar;

function inline(elem, calendarOptions) {
  var o = calendarOptions || {};
  o.appendTo = elem;
  o.associated = elem;
  var cal = calendar(o);
  cal.show();
  return cal;
}

exports = inline;
export default exports;