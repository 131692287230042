var exports = {};

exports = function throttle(fn, boundary) {
  var last = -Infinity;
  var timer;
  return function bounced() {
    if (timer) {
      return;
    }

    unbound();

    function unbound() {
      clearTimeout(timer);
      timer = null;
      var next = last + boundary;
      var now = +new Date();

      if (now > next) {
        last = now;
        fn.apply(this, arguments);
      } else {
        timer = setTimeout(unbound, next - now);
      }
    }
  };
};

export default exports;