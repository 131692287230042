if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (what, start) {
    if (this === undefined || this === null) {
      throw new TypeError();
    }

    var length = this.length;
    start = +start || 0;

    if (Math.abs(start) === Infinity) {
      start = 0;
    } else if (start < 0) {
      start += length;

      if (start < 0) {
        start = 0;
      }
    }

    for (; start < length; start++) {
      if (this[start] === what) {
        return start;
      }
    }

    return -1;
  };
}

export default {};