var hasOwn = Object.prototype.hasOwnProperty;
var hasDontEnumBug = !{
  toString: null
}.propertyIsEnumerable("toString");
var dontEnums = ["toString", "toLocaleString", "valueOf", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "constructor"];
var dontEnumsLength = dontEnums.length;

if (!Object.keys) {
  Object.keys = function (obj) {
    if (typeof obj !== "object" && (typeof obj !== "function" || obj === null)) {
      throw new TypeError("Object.keys called on non-object");
    }

    var result = [],
        prop,
        i;

    for (prop in obj) {
      if (hasOwn.call(obj, prop)) {
        result.push(prop);
      }
    }

    if (hasDontEnumBug) {
      for (i = 0; i < dontEnumsLength; i++) {
        if (hasOwn.call(obj, dontEnums[i])) {
          result.push(dontEnums[i]);
        }
      }
    }

    return result;
  };
}

export default {};