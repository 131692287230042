if (!Array.prototype.filter) {
  Array.prototype.filter = function (fn, ctx) {
    var f = [];
    this.forEach(function (v, i, t) {
      if (fn.call(ctx, v, i, t)) {
        f.push(v);
      }
    }, ctx);
    return f;
  };
}

export default {};