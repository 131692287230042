var exports = {};

function text(elem, value) {
  if (arguments.length === 2) {
    elem.innerText = elem.textContent = value;
  }

  return elem.innerText || elem.textContent;
}

exports = text;
export default exports;