var exports = {};
var no;
var ikey = "data-rome-id";
var index = [];

function find(thing) {
  // can be a DOM element or a number
  if (typeof thing !== "number" && thing && thing.getAttribute) {
    return find(thing.getAttribute(ikey));
  }

  var existing = index[thing];

  if (existing !== no) {
    return existing;
  }

  return null;
}

function assign(elem, instance) {
  elem.setAttribute(ikey, instance.id = index.push(instance) - 1);
}

exports = {
  find: find,
  assign: assign
};
export default exports;