import _index from "./index";
import _parse from "./parse";
import _association from "./association";
var exports = {};
var index = _index;
var parse = _parse;
var association = _association;

function compareBuilder(compare) {
  return function factory(value) {
    var fixed = parse(value);
    return function validate(date) {
      var cal = index.find(value);
      var left = parse(date);
      var right = fixed || cal && cal.getMoment();

      if (!right) {
        return true;
      }

      if (cal) {
        association.add(this, cal);
      }

      return compare(left, right);
    };
  };
}

function rangeBuilder(how, compare) {
  return function factory(start, end) {
    var dates;
    var len = arguments.length;

    if (Array.isArray(start)) {
      dates = start;
    } else {
      if (len === 1) {
        dates = [start];
      } else if (len === 2) {
        dates = [[start, end]];
      }
    }

    return function validate(date) {
      return dates.map(expand.bind(this))[how](compare.bind(this, date));
    };

    function expand(value) {
      var start, end;
      var cal = index.find(value);

      if (cal) {
        start = end = cal.getMoment();
      } else if (Array.isArray(value)) {
        start = value[0];
        end = value[1];
      } else {
        start = end = value;
      }

      if (cal) {
        association.add(cal, this);
      }

      return {
        start: parse(start).startOf("day").toDate(),
        end: parse(end).endOf("day").toDate()
      };
    }
  };
}

var afterEq = compareBuilder(function (left, right) {
  return left >= right;
});
var after = compareBuilder(function (left, right) {
  return left > right;
});
var beforeEq = compareBuilder(function (left, right) {
  return left <= right;
});
var before = compareBuilder(function (left, right) {
  return left < right;
});
var except = rangeBuilder("every", function (left, right) {
  return right.start > left || right.end < left;
});
var only = rangeBuilder("some", function (left, right) {
  return right.start <= left && right.end >= left;
});
exports = {
  afterEq: afterEq,
  after: after,
  beforeEq: beforeEq,
  before: before,
  except: except,
  only: only
};
export default exports;