import * as _crossvent2 from "crossvent";

var _crossvent = "default" in _crossvent2 ? _crossvent2.default : _crossvent2;

import * as _bullseye2 from "bullseye";

var _bullseye = "default" in _bullseye2 ? _bullseye2.default : _bullseye2;

import _throttle from "./throttle";
import _clone from "./clone";
import _defaults from "./defaults";
import _calendar from "./calendar";
import _momentum from "./momentum";
import _classes from "./classes";
var exports = {};
var crossvent = _crossvent;
var bullseye = _bullseye;
var throttle = _throttle;
var clone = _clone;
var defaults = _defaults;
var calendar = _calendar;
var momentum = _momentum;
var classes = _classes;

function inputCalendar(input, calendarOptions) {
  var o = calendarOptions || {};
  o.associated = input;
  var api = calendar(o);
  var throttledTakeInput = throttle(takeInput, 30);
  var ignoreInvalidation;
  var ignoreShow;
  var eye;
  init(o);
  return api;

  function init(initOptions) {
    o = defaults(initOptions || o, api);
    classes.add(api.container, o.styles.positioned);
    crossvent.add(api.container, "mousedown", containerMouseDown);
    crossvent.add(api.container, "click", containerClick);
    api.getDate = unrequire(api.getDate);
    api.getDateString = unrequire(api.getDateString);
    api.getMoment = unrequire(api.getMoment);

    if (o.initialValue) {
      input.value = o.initialValue.format(o.inputFormat);
    }

    eye = bullseye(api.container, input);
    api.on("data", updateInput);
    api.on("show", eye.refresh);
    eventListening();
    throttledTakeInput();
  }

  function destroy() {
    eventListening(true);
    eye.destroy();
    eye = null;
  }

  function eventListening(remove) {
    var op = remove ? "remove" : "add";
    crossvent[op](input, "click", show);
    crossvent[op](input, "touchend", show);
    crossvent[op](input, "focusin", show);
    crossvent[op](input, "change", throttledTakeInput);
    crossvent[op](input, "keypress", throttledTakeInput);
    crossvent[op](input, "keydown", throttledTakeInput);
    crossvent[op](input, "input", throttledTakeInput);

    if (o.invalidate) {
      crossvent[op](input, "blur", invalidateInput);
    }

    if (remove) {
      api.once("ready", init);
      api.off("destroyed", destroy);
    } else {
      api.off("ready", init);
      api.once("destroyed", destroy);
    }
  }

  function containerClick() {
    ignoreShow = true;
    input.focus();
    ignoreShow = false;
  }

  function containerMouseDown() {
    ignoreInvalidation = true;
    setTimeout(unignore, 0);

    function unignore() {
      ignoreInvalidation = false;
    }
  }

  function invalidateInput() {
    if (!ignoreInvalidation && !isEmpty()) {
      api.emitValues();
    }
  }

  function show() {
    if (ignoreShow) {
      return;
    }

    api.show();
  }

  function takeInput() {
    var value = input.value.trim();

    if (isEmpty()) {
      return;
    }

    var date = momentum.moment(value, o.inputFormat, o.strictParse);
    api.setValue(date);
  }

  function updateInput(data) {
    input.value = data;
  }

  function isEmpty() {
    return o.required === false && input.value.trim() === "";
  }

  function unrequire(fn) {
    return function maybe() {
      return isEmpty() ? null : fn.apply(this, arguments);
    };
  }
}

exports = inputCalendar;
export default exports;