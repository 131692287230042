import * as _moment2 from "moment";

var _moment = "default" in _moment2 ? _moment2.default : _moment2;

import _rome from "./rome";
var exports = {};
var moment = _moment;
var rome = _rome;
rome.use(moment);
exports = rome;
export default exports;